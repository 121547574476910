// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutme-js": () => import("./../../../src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-addons-js": () => import("./../../../src/pages/addons.js" /* webpackChunkName: "component---src-pages-addons-js" */),
  "component---src-pages-basics-js": () => import("./../../../src/pages/basics.js" /* webpackChunkName: "component---src-pages-basics-js" */),
  "component---src-pages-class-js": () => import("./../../../src/pages/class.js" /* webpackChunkName: "component---src-pages-class-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keybinds-js": () => import("./../../../src/pages/keybinds.js" /* webpackChunkName: "component---src-pages-keybinds-js" */),
  "component---src-pages-macros-js": () => import("./../../../src/pages/macros.js" /* webpackChunkName: "component---src-pages-macros-js" */),
  "component---src-pages-race-js": () => import("./../../../src/pages/race.js" /* webpackChunkName: "component---src-pages-race-js" */)
}

